<template>
  <div class="form-group c-input-webcam">
    <label
      v-if="label"
      class="form__label"
      :for="slug"
    >
      {{ label }}
    </label>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <div class="c-input-webcam__inner">
      <div class="c-input-webcam__image c-input-webcam__wrapper c-input-webcam__wrapper--video">
        <video
          v-if="fileName || videoUrl"
          :src="videoUrl"
          playsinline
          loop="true"
          autoplay="true"
        ></video>
        <span></span>
      </div>
      <div class="c-input-webcam__wrapper">
        <video
          ref="video"
          autoplay="true"
          muted="true"
          playsinline="true"
        ></video>
      </div>
      <canvas ref="canvas"></canvas>
    </div>
    <span class="second-counter">{{ secondCounter }} seconds</span>

    <div class="c-input-webcam__buttons">
      <button
        class="btn btn-box btn-primary"
        type="button"
        @click="onStartCamera"
        :disabled="processing"
      >
        {{ buttonText }}
      </button>
    </div>
    <div class="c-input-webcam__buttons">
      <CameraPermissionsHelp />
    </div>
    <InputError
      v-if="hasError"
      :message="errorMessage"
    ></InputError>
  </div>
</template>

<script>
import CameraPermissionsHelp from "@swipekit/components/app/camera-permissions-help";
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

import { caams_media_content_type } from "@swipekit/lib/enums";

import api from "@swipekit/lib/api";

export default {
  components: {
    CameraPermissionsHelp,
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      mediaRecorder: null,
      stream: null,
      blobsRecorded: [],
      cameraOn: false,
      videoUrl: "",
      processing: false,
      processingTimeout: null,
      timer: null,
      secondCounter: 0,
      currentImage: "",
      fileName: "",
    };
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
  },

  watch: {
    value: function () {
      let currentValue = this.fileName;

      let value = this.value;

      if (value !== currentValue) {
        this.fileName = value;
      }
    },
  },

  computed: {
    buttonText: function () {
      if (this.cameraOn) {
        return `Stop Rec`;
      }
      return `Start Camera`;
    },
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    imageUrl: function () {
      if (this.currentImage) {
        return this.currentImage;
      }

      let url = `${this.assetUrl}/${this.fileName}`;

      return url;
    },
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
    mimetype: function () {
      let mimetype = "video/mp4";

      if (window.chrome) {
        mimetype = "video/webm";
      }

      return mimetype;
    },
  },

  methods: {
    onChange: function (e) {
      let files = e.target.files;

      let file = files[0];

      if (!file) {
        return;
      }

      let reader = new FileReader();

      reader.onload = (ee) => {
        this.currentImage = ee.target.result;
      };

      reader.readAsDataURL(file);

      this.$emit("input", file);
    },
    onStartCamera: function (e) {
      this.processing = true;
      this.processingTimeout = setTimeout(() => {
        // Disable processing state after 35 seconds in case of unexpected error
        this.processing = false;
      }, 35000);
      if (!this.cameraOn) {
        this.startCamera();
      } else {
        this.stopCamera();
      }
    },
    videoDimensions: function (video, ratio) {
      var actualRatio = video.videoWidth / video.videoHeight; // Ratio of the video's intrisic dimensions
      var offsetRatio = video.offsetWidth / video.offsetHeight; // The ratio of the element's width to its height

      const dimensions = {
        width: video.offsetWidth * ratio,
        height: video.offsetHeight * ratio,
      };

      if (offsetRatio > actualRatio) {
        dimensions.width = dimensions.height * actualRatio; // If the video element is short and wide
      } else {
        dimensions.height = dimensions.width / actualRatio; // It must be tall and thin, or exactly equal to the original ratio
      }

      return dimensions;
    },
    takePhoto: function () {
      let video = this.$refs.video;
      let canvas = this.$refs.canvas;

      let dims = this.videoDimensions(video);
      let ratio = window.devicePixelRatio;

      this.$refs.canvas.width = dims.width * ratio;

      this.$refs.canvas.height = dims.height * ratio;

      canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
      let base64 = canvas.toDataURL("image/jpeg", 1);

      this.currentImage = base64;
    },
    async afterStopVideo(blob) {
      let base64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      }).catch((err) => {
        console.error(err);
        throw err;
      });

      this.$emit("input", base64);
      clearTimeout(this.processingTimeout);
      this.processing = false;
    },
    startMediaRecorder: function () {
      let stream = this.stream;
      let videoUrl = this.videoUrl;
      this.mediaRecorder = new MediaRecorder(stream, {
        mimeType: this.mimetype === "video/webm" ? 'video/webm; codecs="vp8, opus"' : "video/mp4",
        bitsPerSecond: 400000,
      });

      // event : new recorded video blob available
      this.mediaRecorder.addEventListener("dataavailable", (e) => {
        this.blobsRecorded.push(e.data);
      });

      // event : recording stopped & all blobs sent
      this.mediaRecorder.addEventListener("stop", () => {
        let blob = new Blob(this.blobsRecorded, {
          type: this.mimetype,
        });
        URL.revokeObjectURL(videoUrl);
        this.videoUrl = URL.createObjectURL(blob);
        this.blobsRecorded = [];

        this.afterStopVideo(blob);
      });
    },
    startVideoRecording: function () {
      this.mediaRecorder.start(1000);
      this.secondCounter = 0;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.secondCounter += 1;
        if (this.secondCounter >= 30) {
          // set a maximum video length of 60 secs, was 5??
          this.stopCamera();
          this.secondCounter = 0;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    async startCamera() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: "environment",
            width: { ideal: 1920 },
            height: { ideal: 1080 },
          },
          audio: false,
        });
        this.$refs.video.srcObject = this.stream;
        this.cameraOn = true;
      } catch (err) {
        console.error("Error accessing device camera", err);
        switch (err?.name) {
          case "NotAllowedError":
            this.$store.dispatch("message/onCameraPermissionDeniedError");
            break;
          case "NotFoundError":
            this.$store.dispatch("message/onCameraNotFoundError");
            break;
          default:
            this.$store.dispatch("message/onCameraAccessError");
            break;
        }
      }

      if (this.cameraOn) {
        this.startMediaRecorder();
        this.startVideoRecording();

        clearTimeout(this.processingTimeout);
        this.processing = false;
      }
    },
    async stopCamera() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      }
      if (this.$refs.video) {
        this.$refs.video.srcObject = undefined;
      }
      this.destroyCamera();
      clearInterval(this.timer);

      this.cameraOn = false;
    },

    destroyCamera: function () {
      if (this.stream) {
        this.stream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    },
    async constructUrl(id) {
      let media = await api.medias.getById(id).catch((err) => {
        console.error(err);
        throw err;
      });
      let mime = this.resolveMime(media.content_type);
      URL.revokeObjectURL(this.videoUrl);
      this.videoUrl = `${this.assetUrl}/media/${id}.${mime}`;
    },
    resolveMime: function (content_type) {
      let mime = "";

      for (let i = 0; i < caams_media_content_type.length; i++) {
        if (caams_media_content_type[i].label === content_type) {
          mime = caams_media_content_type[i].value;
          break;
        }
      }

      return mime;
    },
  },

  mounted: function () {
    let value = this.value;
    if (value && typeof value === "number") {
      this.constructUrl(value);
    }
  },

  beforeDestroy: function () {
    this.destroyCamera();
    URL.revokeObjectURL(this.videoUrl);
  },
};
</script>

<style lang="scss">
.c-input-webcam {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  .second-counter {
    margin-bottom: 10px;
  }

  &__image {
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    margin-right: var(--margin-lg);
    background-color: var(--color-bg-5);

    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }
  video {
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-5);
    border-radius: var(--border-radius);
    margin-right: var(--margin-lg);
  }

  &__inner {
    margin-top: 10px;
  }

  &__inner,
  &__buttons {
    width: auto;
    display: grid;
    grid-template-columns: 350px 350px;
    grid-column-gap: 10px;
    margin-bottom: 10px;

    & > * {
      min-width: 140px;
      max-width: 350px;
      width: 100%;
    }

    .form-control,
    .btn {
      width: 100%;
      max-height: initial;
      border-radius: var(--border-radius);
      height: var(--spacer-lg);
      margin-bottom: 0 !important;
      padding: var(--margin-lg);
    }
  }

  canvas {
    display: none;
  }

  &__wrapper {
    position: relative;

    &:after {
      display: inline-block;
      content: "Preview";
      position: absolute;
      top: var(--margin);
      left: calc(50% - 40px);
      width: 80px;
      padding: var(--margin-sm) var(--margin);
      text-transform: uppercase;
      text-align: center;
      font-size: var(--font-size-xs);
      font-weight: 500;
      background-color: var(--color-bg-1);
      border-radius: var(--border-radius-full);
    }

    &--video {
      &:after {
        content: "Video";
        left: calc(50% - 30px);
        width: 60px;
      }
    }
  }

  @media screen and (max-width: 720px) {
    &__inner {
      display: grid;
      width: 100%;
      grid-column-gap: 10px;
      grid-template-columns: 1fr 1fr;
    }

    &__buttons {
      width: 100%;
      grid-template-columns: 1fr 1fr;
    }

    &__image {
      width: 100%;
    }

    video {
      width: 100%;
    }
  }
}
</style>
